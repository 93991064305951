import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router';
import schemaGenerator from 'helpers/schemaGenerator';
import { getEnPath, getEsPath, site } from '../../helpers/i18n'

const Head = ({
  siteTitle,
  siteDescription,
  siteUrl,
  pageTitle,
  pageDescription,
  defaultTitle,
  // TODO pageDescription
  //  -- add to all projects and services
  // pageTitleFull = pageTitle ? `${pageTitle} - ${siteTitle}` : defaultTitle,
  // siteDescriptionFull = pageDescription
  //   ? pageDescription
  //   : pageTitle
  //   ? `${pageTitle} - ${siteDescription}`
  //   : siteDescription,

  themeColor,
  social,
  imageUrl,
  location,
  canonical = siteUrl + (location.pathname || ''),
  lang = 'es'
}) => {
  
  siteTitle = lang === 'en' ? site.en.siteTitle : site.es.siteTitle
  // let siteTitleShort = lang === 'en' ? site.en.siteTitleShort : site.es.siteTitleShort
  defaultTitle = lang === 'en' ? site.en.defaultTitle : site.es.defaultTitle
  siteDescription = lang === 'en' ? site.en.siteDescription : site.es.siteDescription

  let pageTitleFull = pageTitle ? `${pageTitle} - ${siteTitle}` : defaultTitle
  let siteDescriptionFull = pageDescription
    ? pageDescription
    : pageTitle
      ? `${pageTitle} - ${siteDescription}`
      : siteDescription

  
  const schema = JSON.stringify(
    schemaGenerator({
      location,
      canonical,
      siteUrl,
      pageTitle,
      siteTitle,
      pageTitleFull,
      siteDescriptionFull,
    })
  );

  const esPath = getEsPath(location.pathname, lang)
  const enPath = getEnPath(location.pathname, lang)
  
  return (
    <Helmet>
      <html lang={lang} />
      <meta httpEquiv="Content-Language" content={lang} />
      <link rel="canonical" href={canonical} />
      <meta property="og:url" content={canonical} />
      <meta name="twitter:url" content={canonical} />

      {esPath && <link rel="alternate" hrefLang="x-default" href={siteUrl + esPath} />}
      {esPath && <link rel="alternate" hrefLang="es" href={siteUrl + esPath} />}
      {enPath && <link rel="alternate" hrefLang="en" href={siteUrl + enPath} />}

      {/* <meta property="og:locale" content={lang} />
      <meta property="og:locale:alternate" content={lang} /> */}

      <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
      <meta
        content="width=device-width,initial-scale=1.0,user-scalable=yes"
        name="viewport"
      />
      <meta content={siteTitle} name="apple-mobile-web-app-title" />
      <meta content={pageTitleFull} property="og:title" />
      <meta content={pageTitleFull} name="twitter:title" />
      <title>{pageTitleFull}</title>

      <meta content={siteDescriptionFull} name="description" />
      <meta content={siteDescriptionFull} property="og:description" />
      <meta content={siteDescriptionFull} name="twitter:description" />

      <meta content="yes" name="apple-mobile-web-app-capable" />
      <meta
        content="black-translucent"
        name="apple-mobile-web-app-status-bar-style"
      />
      <meta content={themeColor} name="theme-color" />
      <meta content={siteTitle} name="application-name" />

      <meta content="website" property="og:type" />
      <meta content="Visualma" property="og:site_name" />
      <meta content={social.fbAppId} property="fb:app_id" />
      <meta content="summary_large_image" name="twitter:card" />
      <meta content={`@${social.twitter}`} name="twitter:site" />
      <meta content={`@${social.twitter}`} name="twitter:creator" />
      <meta content={pageTitleFull} name="twitter:text:title" />

      <meta
        content={imageUrl || `${siteUrl}/socialLogo.jpg`}
        property="og:image"
      />
      <meta content="1024" property="og:image:width" />
      <meta content="512" property="og:image:height" />
      <meta
        content={imageUrl || `${siteUrl}/socialLogo.jpg`}
        name="twitter:image"
      />
      <meta content="1024" name="twitter:image:width" />
      <meta content="512" name="twitter:image:height" />

      <meta content={themeColor} name="msapplication-TileColor" />
      <meta
        content="/icons/mstile-70x70.png"
        name="msapplication-square70x70"
      />
      <meta
        content="/icons/mstile-144x144.png"
        name="msapplication-square144x144"
      />
      <meta
        content="/icons/mstile-150x150.png"
        name="msapplication-square150x150"
      />
      <meta
        content="/icons/mstile-310x150.png"
        name="msapplication-wide310x150"
      />
      <meta
        content="/icons/mstile-310x310.png"
        name="msapplication-square310x310"
      />

      <link href="/manifest.json" rel="manifest" />

      <link
        href="/icons/apple-touch-icon-57x57.png"
        rel="apple-touch-icon"
        sizes="57x57"
      />
      <link
        href="/icons/apple-touch-icon-60x60.png"
        rel="apple-touch-icon"
        sizes="60x60"
      />
      <link
        href="/icons/apple-touch-icon-72x72.png"
        rel="apple-touch-icon"
        sizes="72x72"
      />
      <link
        href="/icons/apple-touch-icon-76x76.png"
        rel="apple-touch-icon"
        sizes="76x76"
      />
      <link
        href="/icons/apple-touch-icon-114x114.png"
        rel="apple-touch-icon"
        sizes="114x114"
      />
      <link
        href="/icons/apple-touch-icon-120x120.png"
        rel="apple-touch-icon"
        sizes="120x120"
      />
      <link
        href="/icons/apple-touch-icon-144x144.png"
        rel="apple-touch-icon"
        sizes="144x144"
      />
      <link
        href="/icons/apple-touch-icon-152x152.png"
        rel="apple-touch-icon"
        sizes="152x152"
      />
      <link
        href="/icons/apple-touch-icon-167x167.png"
        rel="apple-touch-icon"
        sizes="167x167"
      />
      <link
        href="/icons/apple-touch-icon-180x180.png"
        rel="icon"
        sizes="180x180"
        type="image/png"
      />

      {/* <link
        href="/icons/favicon-32x32.png"
        rel="icon"
        sizes="32x32"
        type="image/png"
      />
      <link
        href="/icons/favicon-16x16.png"
        rel="icon"
        sizes="16x16"
        type="image/png"
      /> */}
      <link rel="icon" type="image/svg+xml" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 width=%22256%22 height=%22256%22 viewBox=%220 0 100 100%22><rect width=%22100%22 height=%22100%22 rx=%2250%22 fill=%22%23000000%22></rect><text x=%2250%%22 y=%2250%%22 dominant-baseline=%22central%22 text-anchor=%22middle%22 font-size=%2290%22>👁️</text></svg>" />

      
      <link rel="preconnect" href="https://www.youtube.com" />
      <link rel="preconnect" href="https://www.youtube-nocookie.com" />
      <link rel="preconnect" href="https://i.ytimg.com" />
      <link rel="preconnect" href="https://i9.ytimg.com" />
      <link rel="preconnect" href="https://s.ytimg.com" />

      <link rel="preconnect" href="https://player.vimeo.com" />
      <link rel="preconnect" href="https://i.vimeocdn.com" />
      <link rel="preconnect" href="https://f.vimeocdn.com" />

      <script type="application/ld+json">{schema}</script>

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
      <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap" rel="stylesheet"></link>
    </Helmet>
  );
};

Head.propTypes = {
  siteTitle: PropTypes.string,
  siteTitleShort: PropTypes.string,
  pageDescription: PropTypes.string,
  siteDescription: PropTypes.string,
  siteDescriptionFull: PropTypes.string,
  siteUrl: PropTypes.string,
  themeColor: PropTypes.string,
  social: PropTypes.objectOf(PropTypes.string),
  imageUrl: PropTypes.string,
  canonical: PropTypes.string,
  defaultTitle: PropTypes.string,
  pageTitle: PropTypes.string,
  pageTitleFull: PropTypes.string,
  location: PropTypes.object.isRequired,
  lang: PropTypes.string,
};

const HeadWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            siteTitleShort
            siteDescription
            siteUrl
            themeColor
            social {
              twitter
              fbAppId
            }
          }
        }
      }
    `}
    render={(data) => (
      <Location>
        {({ location }) => (
          <Head {...data.site.siteMetadata} {...props} location={location} />
        )}
      </Location>
    )}
  />
);

export default HeadWithQuery;
