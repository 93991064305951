export const accent = 'var(--accent-color)'; //'#30bbe5';
export const accentText = 'var(--accent-color-txt)'; //'#1c9bc1';
export const accentLight = '#1f9ee477'; //'#1f9ee45e';
export const accentLighter = 'rgba(0, 196, 255, 0.53)';
export const accentTransparent = 'rgba(0, 196, 255, 0.06)';
export const maxContainerWidth = '1248px';
export const contentContainer = `
  max-width: ${maxContainerWidth};
  margin: 0 auto;
  padding: 4rem 3rem;`;
