import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Header from 'components/header';
import Footer from 'components/footer';
import GlobalStyle from 'global.css.js';

const Layout = ({ lang = 'es', children }) => ( //, data
  <div>
    <GlobalStyle />
    {/* <Head /> */}
    <Header lang={lang} />
    <main
      style={{
        paddingTop: 53,
        overflowX: 'hidden',
        // minHeight: 'calc(100vh - 53px - 239px)',
      }}
    >
      {children}
    </main>
    <Footer lang={lang} />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  lang: PropTypes.string
};

const LayoutWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={(data) => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
