import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'
import { routes } from '../helpers/i18n'
import { Link } from 'gatsby'
import { IoEarth } from 'react-icons/io5';
import Icon from '@chakra-ui/icon'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu'

const LangSelector = ({ lang, size, ...rest }) => {
  const location = useLocation()
  const pathname = location.pathname
  {/* TODO: path correcto en secciones que tienen traduccion, 
      - actualizar routes en i18n  */ }
  // const path = getLocalizedPath(key, lang)
  const path = lang === 'es' 
    ? (routes?.[pathname] || '/en') 
    : Object.keys(routes).find(key => routes[key] === pathname) || '/';

  return (
    <Link to={path} {...rest}>
      <Icon as={IoEarth} w={4} h={4} mr={1}/>
      {size == 'large' 
        ? lang === 'en' 
          ? 'Versión en Español' : 'English version'
        : lang === 'en' 
          ? 'Es' : 'En'
      }
    </Link>
  )
}
LangSelector.propTypes = {
  lang: PropTypes.string,
  size: PropTypes.string,
};

const LangSelectorMenu = ({ lang, size, ...rest }) => {
  const location = useLocation()
  const pathname = location.pathname
  {/* TODO: path correcto en secciones que tienen traduccion, 
      - actualizar routes en i18n  */ }
  // const path = getLocalizedPath(key, lang)
  const path = lang === 'es' 
    ? (routes?.[pathname] || '/en') 
    : Object.keys(routes).find(key => routes[key] === pathname) || '/';

  return (
    // <Link to={path} {...rest}>
    //   <Icon as={MdLanguage} w={4} h={4} mr={1}/>
    //   {size == 'large' 
    //     ? lang === 'en' 
    //       ? 'Versión en Español' : 'English version'
    //     : lang === 'en' 
    //       ? 'Es' : 'En'
    //   }
    // </Link>
    <Menu autoSelect={false}>
      <MenuButton as={'button'}>
        <Icon as={IoEarth} w={4} h={4} mr={1}/>
      </MenuButton>
      <MenuList boxShadow="0 15px 25px hsl(0deg 0% 0% / 15%), 0 5px 10px hsl(0deg 0% 0% / 5%)">

        <Link to={path}>
          <MenuItem>
            {lang === 'en'
            ? 'Versión en Español' : 'English version'}
          </MenuItem>
        </Link>

      </MenuList>
    </Menu>
  )
}
LangSelectorMenu.propTypes = {
  lang: PropTypes.string,
  size: PropTypes.string,
};
export { LangSelector, LangSelectorMenu }