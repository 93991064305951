import getVideoId from 'get-video-id';
import React from 'react';
import { Helmet } from 'react-helmet';

const schemaGenerator = ({
  location,
  canonical,
  siteUrl,
  pageTitle,
  siteTitle,
  pageTitleFull,
  siteDescriptionFull,
}) => {
  /* eslint-disable */
  const isSubPage = pageTitle && location.pathname !== '/';

  let schema = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      "name": "Visualma",
      "url": "https://visualma.com",
      "sameAs": [
        "http://www.facebook.com/visualma",
        "http://www.twitter.com/visualma",
        "http://www.youtube.com/visualma"
      ]
    },
    {
      "@context": 'http://schema.org',
      "@type": 'WebPage',
      url: canonical,
      name: pageTitle || siteTitle,
      alternateName: pageTitleFull,
      description: siteDescriptionFull
    },
    {
      "@context": 'https://schema.org',
      "@type": 'Organization',
      'url': 'https://visualma.com',
      'logo': 'https://visualma.com/miniLogo-visualma.png',
      'name': 'Visualma',
      'contactPoint': {
        '@type': 'ContactPoint',
        'telephone': '3338253793',
        'email': 'contacto@visualma.com',
      },
      "sameAs": [
        "http://www.facebook.com/visualma",
        "http://www.twitter.com/visualma",
        "http://www.youtube.com/visualma"
      ]
    },
  ];

  if (location.pathname.indexOf('proyectos') != -1) {
    schema.push({
      "@context": 'http://schema.org',
      "@type": 'BreadcrumbList',
      itemListElement: [
        {
          "@type": 'ListItem',
          position: 1,
          item: {
            "@id": siteUrl,
            name: "Visualma",
          },
        },
        {
          "@type": 'ListItem',
          position: 2,
          item: {
            "@id": "https://visualma.com/proyectos",
            name: "Proyectos",
          },
        },
        {
          "@type": 'ListItem',
          position: 3,
          item: {
            "@id": canonical,
            name: pageTitle,
          },
        },
      ],
    });
  }
  else if (isSubPage) {
    schema.push({
      "@context": 'http://schema.org',
      "@type": 'BreadcrumbList',
      itemListElement: [
        {
          "@type": 'ListItem',
          position: 1,
          item: {
            "@id": siteUrl,
            name: siteTitle,
          },
        },
        {
          "@type": 'ListItem',
          position: 2,
          item: {
            "@id": canonical,
            name: pageTitle,
          },
        },
      ],
    });
  }
  
  return schema;
};



// TODO abstract video embeds in a component and add videoSchema automaticaly
const VideoSchema = ({ videoUrl, contentUrl, imageUrl, title, description, date, duration}) => {
  const { id, service } = typeof videoUrl === 'string'
    ? getVideoId(videoUrl) : ({ id: null, service: null });
  let embedUrl = null;
  if (videoUrl && id && service === 'vimeo') {
    embedUrl = `https://player.vimeo.com/video/${id}`;
  } else if (service === 'youtube') {
    embedUrl = `https://www.youtube.com/embed/${id}`;
  }
  if (!imageUrl){
    imageUrl = `https://i.ytimg.com/vi/${id}/default.jpg`
  }
  let schema = id && embedUrl ? {
    /* eslint-disable */
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "name": title,
    "description": description,
    "thumbnailUrl": imageUrl,
    "contentUrl": contentUrl,
    "embedUrl": embedUrl,
    "uploadDate": new Date(date).toISOString()
  } : {}
  if (duration) {
    schema['duration'] = duration
  }
  return id && embedUrl
    ? (
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      </Helmet>
    ) : null;
}

export { schemaGenerator as default, VideoSchema }