import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { maxContainerWidth } from '../constants/theme';
import MEDIA from 'helpers/mediaTemplates';
import { Link } from 'gatsby';
import { Heading, Flex, Box, useColorMode } from '@chakra-ui/react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import waveSvg from '../../static/wave.svg'
import { getLocalizedPath, useTranslation } from '../helpers/i18n';
import { LangSelector } from './langSelector';
import { useGoal } from 'gatsby-plugin-fathom';

const Footer = ( {lang = 'es'} ) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const t = (key) => useTranslation(key, lang)
  const tPath = (key) => getLocalizedPath(key, lang)
  const callGoal = useGoal('GPFVVLSQ')
  const mailGoal = useGoal('VPOUNW6Q')

  return (
    <>
      <div className='wave' style={{marginTop: '-41px'}}>
        <img alt="" src={waveSvg} width="100%" height="40" 
        style={{aspectRatio: 1400/57.59}}/>
      </div>
      <FooterContainer>
        <Flex
          id="innerContent"
          justifyContent="space-around"
          flexDirection={['column-reverse',  'column-reverse', 'row']}
        >
          <Flex
            flexDirection="column"
            flex="1 0"
            p={['0 0', '0 0.1rem', '0 0.3rem', '0rem 2em', '0rem 2em']}
            borderRight={['none', '1px solid #1d1c1c4a']}
            pb={['2rem', '2rem']}
          >
            <Heading size="sm" mb="2rem">
              Visualma
            </Heading>
            <Box 
              textAlign={['left', 'left']}
              marginTop={['2em', 0]}
              maxWidth={['260px', 'auto']}
              margin={['2em auto 0 auto']}>
              <ul>
                <li>
                  <Link to={tPath('/proyectos')}>
                    {t('Proyectos')}
                  </Link>
                </li>
                <li>
                  <Link to={tPath('/videos')}>
                    {t('Videos')}
                  </Link>
                </li>
                <li>
                  <Link to={tPath('/realidad-virtual')}>
                    {t('Realidad Virtual')}
                  </Link>
                </li>
                <li>
                  <Link to={tPath('/realidad-aumentada')}>
                    {t('Realidad Aumentada')}
                  </Link>
                </li>
                <li>
                  <Link to={tPath('/capacitacion-realidad-virtual')}>
                    {t('Capacitación con Realidad Virtual')}
                  </Link>
                </li>

                <li>
                  <Link to={tPath('/set-virtual-produccion-cine-tv')}>
                    {t('Sets / Escenarios virtuales')}
                  </Link>
                </li>
                
                <li>
                  <Link to={tPath('/video-mapping')}>
                    {t('Video Mapping 3D')}
                  </Link>
                </li>
                <li>
                  <Link to={tPath('/animacion-modelado-3d')}>
                    {t('Animación y Modelado 3D')}
                  </Link>
                </li>
                <li>
                  <Link to={tPath('/museos-instalaciones')}>
                    {t('Instalaciones para Museos')}
                  </Link>
                </li>
                <li>
                  <Link to={tPath('/arquitectura-recorrido-virtual')}>
                    {t('Arquitectura y recorridos virtuales')}
                  </Link>
                </li>
                <li>
                  <Link to={tPath('/aplicaciones-videojuegos')}>
                    {t('Apps y Videojuegos')}
                  </Link>
                </li>
                <li>
                  <Link to={tPath('/desarrollo-web-apps')}>
                  {t('Programación Web')}
                  </Link>
                </li>
                <li>
                  <br />
                  <LangSelector lang={lang} size='large' />
                </li>
              </ul>
            </Box>
          </Flex>
          <Flex
            flexDirection="column"
            flex="1 0"
            p={['0 1rem', '0 1rem', '0 0rem']}
            borderRight={['none', '1px solid #1d1c1c4a']}
            pb={['2rem', '0']}
            display={['none', 'none', 'flex-inline', 'flex']}
          >
            <Heading size="sm" mb="2rem">
              Redes
            </Heading>
            <ul style={{ textAlign: 'center', display: 'inlne-block'}}>
              <li>
                <a
                  href="https://www.linkedin.com/company/visualma"
                  title="Visualma en LinkedIn"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href="https://youtube.com/visualmamexico"
                  title="Visualma en Youtube"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  YouTube
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/Visualma"
                  title="Visualma en Facebook"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.twitter.com/visualma"
                  title="Visualma en Twitter"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://www.vimeo.com/visualma"
                  title="mira los videos de Visualma en vimeo"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Vimeo
                </a>
              </li>
              {/* <li>
                <Button size="sm" onClick={toggleColorMode}>
                  Toggle {colorMode === "light" ? "Dark" : "Light"}
                </Button>
                <Switch 
                  size="sm"
                  onClick={toggleColorMode}
                  isChecked={colorMode != 'light'}
                />
              </li> */}
            </ul>
            
            
          </Flex>
          <Flex 
            flexDirection="column"
            flex="1 0" 
            p={['1em 0rem 2em', '0 1rem', '6rem 2em', '6rem 2em']}
            textAlign={['left', 'center']} 
            borderBottom={['3px solid #126897aa', 'none']}
            margin={['0 auto', 'auto']}
            maxWidth={['260px', '100%']}
          >
            <Heading size="sm" mb="2rem">
              {t('Contáctanos')}
            </Heading>
            <ul>
              <li>
                <OutboundLink
                  href={`mailto:${t('contacto@visualma.com')}`}
                  title="Informes y cotizaciónes"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}
                  onClick={(e) => { mailGoal() }}
                >
                  <EmailIcon mr="2" />
                  {t('contacto@visualma.com')}
                </OutboundLink>
              </li>
              <li>
                <OutboundLink
                  href={`tel:${t('3338253793')}`}
                  title="Teléfono para Informes y cotizaciónes"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{fontSize: '1.2em'}}
                  onClick={(e) => { callGoal() }}
                >
                  <PhoneIcon mr="2" />
                  {t('33 3825-3793')}
                </OutboundLink>
              </li>
            </ul>
          </Flex>
        </Flex>
      </FooterContainer>
    </>
  );
}
Footer.propTypes = {
  lang: PropTypes.string,
};

const FooterContainer = styled.footer`
  color: #fff;
  padding: 1rem 3rem 1rem;
  /* background: #229abe;
  background: linear-gradient(
    160deg,
    #229abe,
    #229abe,
    #2fabd9,
    #43ade0,
    #2ab1da,
    #4fb3d4,
    #35a8d5
  ); */
  
  /* background-image: linear-gradient(315deg, #49a4d5 0%, #1c9bc1 100%); */
  background: hsl(201 79% 38% / 1);//var(--accent-color);
  /* background: #006080; */
  
  #innerContent {
    max-width: ${maxContainerWidth};
    margin: 0 auto;
  }
  h2 {
    width: 100%;
    text-align: center;
    text-shadow: 3px 3px 4px #013c5a;
    display: none;
  }
  ul {
    li {
      padding: 2px;
      a {
        color: #fff;
        text-decoration: none;
        font-size: 0.9rem;
      }
    }
  }

  ${MEDIA.PHONE`
    ul {
      li {
        padding: 16px;
      }
    }
  `};
`;

export default Footer;
