import React from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';

import styled from 'styled-components';
import Nav from 'components/header/nav';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import logo from '../../../static/logoVisualmaCurvas.svg';
import { myContext } from '../../store/provider';
import { useContext } from 'react';
const ContactChat = React.lazy(() => import('../contactChat'));
import { trackCustomEvent } from '../../helpers/functions';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { useTranslation } from '../../helpers/i18n';
import { useGoal } from 'gatsby-plugin-fathom'

const Contacto = ({ isOpen, onClose, lang }) => {
  const t = (key) => useTranslation(key, lang)
  const isSSR = typeof window === 'undefined';
  const size = useBreakpointValue(['xs', 'sm', 'sm'])
  const callGoal = useGoal('GPFVVLSQ')
  const mailGoal = useGoal('VPOUNW6Q')
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      // size={["xs", "sm", "sm", "sm"]}
      size={size}
    >
      <DrawerOverlay />
      <DrawerContent w={['90vw', '380px']}>
        <DrawerCloseButton style={{ marginTop: '53px' }} />
        <DrawerHeader style={{ color: '#187e9c', textAlign: 'center' }}>
          &nbsp;
        </DrawerHeader>

        <DrawerBody
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <Flex
            textAlign="center"
            color="white"
            p="0"
            flexDirection="column"
            alignItems="center"
            marginTop="40px"
            height="270px"
          >
            <img
              src={logo}
              alt="Visualma Mexico"
              width="200" height="38.48"
              style={{ maxWidth: '200px' }}
            />
            <div
              style={{
                maxWidth: '800px',
                margin: '3em auto',
                color: '#222',
                lineHeight: '1.3em',
              }}
            >
              {t('¡Hagamos realidad tu proyecto!')}
            </div>
            <Flex justifyContent="center" flexDirection="column">
              <OutboundLink
                href={`tel:${t('3338253793')}`}
                style={{
                  color: 'var(--accent-color-txt)',
                  fontSize: '20px',
                  textDecoration: 'none',
                  fontWeight: 'bold',
                }}
                onClick={(e) => { callGoal() }}
              >
                <PhoneIcon mr="8px" /> {t('33 3825-3793')}
              </OutboundLink>
              <Button
                as={OutboundLink}
                href={`mailto:${t('contacto@visualma.com')}`}
                target="_blank"
                colorScheme="azulin"
                variant="outline"
                p="1em 5em"
                maxWidth="260px"
                m="0 auto"
                size="md"
                style={{ marginTop: '22px' }}
                leftIcon={<EmailIcon mr="2"/>}
                onClick={(e) => { mailGoal() }}
              >
                {t('contacto@visualma.com')}
              </Button>
            </Flex>
          </Flex>
          <Flex position="relative" height="calc(100vh - 355px)">
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <ContactChat lang={lang} />
              </React.Suspense>
            )}
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
Contacto.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  lang: PropTypes.string
};

const Header = ({lang='es'}) => {
  const { isDrawerOpen, toggleDrawer, openDrawer, closeDrawer } = useContext(myContext);
  const openContactoGoal = useGoal('AHHHXPYM');
  const openContacto = () => {
    openContactoGoal()
    trackCustomEvent('open_contacto', {
      event_category: 'contacto',
      event_label: 'Contact Main Nav',
      non_interaction: true,
      value: 1,
    });

    openDrawer();
  };

  return (
    <React.Fragment>
      <Container>
        <Link to={lang === 'en' ? '/en' : '/'} onFocus={closeDrawer} onContextMenu={
          (e) => { 
            e.preventDefault(); 
            navigate('/videos')
          }
        }>
          <img src={logo} alt="Visualma Mexico" width="200" height="38.48" />
        </Link>

        <Nav
          openContacto={openContacto}
          closeContacto={closeDrawer}
          toggleContacto={toggleDrawer}
          lang={lang}
        />
      </Container>
      <Contacto isOpen={isDrawerOpen} onClose={closeDrawer} lang={lang} />
    </React.Fragment>
  );
};
Header.propTypes = {
  lang: PropTypes.string
  // isOpen: PropTypes.bool.isRequired,
  // onOpen: PropTypes.func.isRequired,
  // onClose: PropTypes.func.isRequired,
};

const Container = styled.header`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0 0.3rem;
  box-shadow: 2px 2px 6px #666;
  background: rgba(255, 255, 255, 0.68);
  z-index: 9999;

  //backdrop-filter: blur(10px);
  // &::before {
  //   content: '';
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   backdrop-filter: blur(10px);
  //   z-index: -1;
  // }
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    --extended-by: 100px;
    bottom: calc(-1 * var(--extended-by));
    --cutoff: calc(100% - var(--extended-by));
    backdrop-filter: blur(10px);
    z-index: -1;
    --blur: 10px;
    -webkit-backdrop-filter: blur(var(--blur));
    backdrop-filter: blur(var(--blur));
    -webkit-mask-image: linear-gradient(to bottom, black 0, black var(--cutoff), transparent var(--cutoff));
    pointer-events: none;
  }

  img {
    margin-right: 16px;
    margin-left: 16px;
    height: auto;
    width: 200px;
  }
`;

export default Header;
