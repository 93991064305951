import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  MenuDivider,
  Icon,
  VisuallyHidden,
  forwardRef,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { MdOndemandVideo } from 'react-icons/md';
import { GiJumpAcross } from 'react-icons/gi';
import { BiMailSend } from 'react-icons/bi';
import { IoLogoGameControllerB } from 'react-icons/io';
import { FcVideoProjector, FcCamcorderPro, FcSelfie, FcLandscape, FcIdea, FcReading, FcCellPhone, FcDam, FcInTransit } from 'react-icons/fc';
// RiProjector2Fill
// GiFilmProjector, GiProtectionGlasses
// IoLogoGameControllerB
import {LangSelector, LangSelectorMenu} from '../../langSelector'
import { getLocalizedPath, useTranslation } from '../../../helpers/i18n';

// const MyLink = ({ ...rest }) => <Link activeClassName="active" {...rest} />;
// const MyLink = React.forwardRef(
//   function generarLink(props, ref) { 
//     return (<Link activeClassName="active" innerRef={ref} {...props} />)
//   }
// );

const MyLink = forwardRef(({...props}, ref) => (
  <Link 
    activeClassName="active" ref={ref} {...props} />
))
const MyLink2 = forwardRef(({ ...props }, ref) => (
  <Link 
    activeClassName="active2" ref={ref} {...props} />
))

const Nav = ({lang='es', openContacto, closeContacto}) => 
{
  const t = (key) => useTranslation(key, lang)
  const tPath = (key) => getLocalizedPath(key, lang)
  const MenuItemsServicios = () => (
    <>
      <MyLink2 to={tPath('/realidad-virtual')}>
        <MenuItem 
        icon={<Icon as={FcLandscape} w={5} h={5} />}>
        {t('Realidad Virtual')}
        </MenuItem>
      </MyLink2>
      <MyLink2 to={tPath('/realidad-aumentada')}>
        <MenuItem 
        icon={<Icon as={FcSelfie} w={5} h={5} />}>
        {t('Realidad Aumentada')}
        </MenuItem>
      </MyLink2>
      <MyLink2 to={tPath('/capacitacion-realidad-virtual')}>
        <MenuItem 
        icon={<Icon as={FcInTransit} w={5} h={5} />}>
        {t('Capacitación con realidad virtual')}
        </MenuItem>
      </MyLink2>
      <MyLink2 to={tPath('/arquitectura-recorrido-virtual')}>
        <MenuItem 
        icon={<Icon as={FcDam} w={5} h={5} />}>
        {t('Arquitectura Virtual')}
        </MenuItem>
      </MyLink2>
      <MyLink2 to={tPath('/set-virtual-produccion-cine-tv')}>
        <MenuItem 
        icon={<Icon as={FcCamcorderPro} w={5} h={5} />}>
        {t('Producción Virtual / Cine')}
        </MenuItem>
      </MyLink2>
      <MyLink2 to={tPath('/animacion-modelado-3d')}>
        <MenuItem 
        icon={<Icon as={GiJumpAcross} w={5} h={5} />}>
        {t('Animación y Video')}
        </MenuItem>
      </MyLink2>
      <MyLink2 to={tPath('/video-mapping')}>
        <MenuItem 
        icon={<Icon as={FcVideoProjector} w={5} h={5} />}>
        {t('Video Mapping 3D')}
        </MenuItem>
      </MyLink2>
      <MyLink2 to={tPath('/museos-instalaciones')}>
        <MenuItem 
        icon={<Icon as={FcReading} w={5} h={5} />}>
        {t('Museos')}
        </MenuItem>
      </MyLink2>
      <MyLink2 to={tPath('/aplicaciones-videojuegos')}>
        <MenuItem 
        icon={<Icon as={IoLogoGameControllerB} w={5} h={5} />}>
        {t('Apps y Videojuegos')}
        </MenuItem>
      </MyLink2>
    </>
  )

  return ( 
    <Container>
      <Box
        display={{
          base: 'none',
          sm: 'inline-flex',
          md: 'inline-flex',
          lg: 'inline-flex',
        }}
      >
        <ul>
          <li>
            <Menu autoSelect={false}>
              <MenuButton as={'button'} 
                onFocus={closeContacto}
              >
                {t('Servicios')} <ChevronDownIcon />
              </MenuButton>
              <MenuList className="menuServicios" boxShadow="0 15px 25px hsl(0deg   0% 0% / 15%), 0 5px 10px hsl(0deg 0% 0% / 5%)" maxHeight="calc(100vh - 70px)" overflow="auto">

                <MenuItemsServicios />
              
              </MenuList>
            </Menu>
          </li>
          <li>
            <MyLink2 
              to={tPath('/proyectos')}
              activeClassName="active"
              className="navLink"
              partiallyActive={true}
              onFocus={closeContacto}
            >     
              {t('Proyectos')} 
            </MyLink2>
          </li>
          <li>
            <button onClick={openContacto} className="btnContact"
            title={t('Contáctanos')} aria-label={t('Contáctanos')} 
            >
              {/* <VisuallyHidden>{t('Contáctanos')}</VisuallyHidden> */}
              <Icon as={BiMailSend} w={6} h={6} mr={2}    
                title={t('Contáctanos')} aria-label={t('Contáctanos')}/>
                {t('Contáctanos')}
            </button>
          </li>
          <li>
            <MyLink
              to={tPath('/videos')}
              activeClassName="active"
              className="navLink"
              partiallyActive={true}
              onFocus={closeContacto}
            >
              <Icon as={MdOndemandVideo} w={5} h={5} title="Videos by Visualma" />
              <VisuallyHidden>Videos</VisuallyHidden>
            </MyLink>
          </li>
          <li>
            {/* <LangSelector lang={lang}/> */}
            <LangSelectorMenu lang={lang}/>
          </li>
        </ul>
      </Box>
      <Box
        display={{ base: 'inline-flex', sm: 'none', md: 'none', lg: 'none' }}
      >
        <ul>
          <li>
            <Menu autoSelect={false}>
              <MenuButton as='button' onClick={closeContacto}>
                Menu <ChevronDownIcon />
              </MenuButton>
              <MenuList 
                boxShadow="0 15px 25px hsl(0deg 0% 0% / 15%), 0 5px 10px hsl(0deg 0% 0% / 5%)"
                maxHeight="calc(100vh - 70px)"
                overflow="auto"
                background={'transparent'}
                backdropBlur={'10px'}
                style={{
                  backdropFilter: 'blur(10px)',
                  background: 'rgba(255, 255, 255, 0.68)'
                }}
              >
                <MyLink2 to={tPath('/proyectos')}>
                  <MenuItem  icon={<Icon as={FcIdea} w={5} h={5}/>}>
                    {t('Proyectos')}
                  </MenuItem>
                </MyLink2>
                

                <MenuDivider />

                <MenuItemsServicios />

                <MyLink2 to={tPath('/videos')}>
                  <MenuItem
                    icon={<Icon as={MdOndemandVideo} w={5} h={5} color="#e41f1f" />}>
                    {t('Videos')}
                  </MenuItem>
                </MyLink2>

                <MenuDivider />
                
                <MenuItem as={'button'} onClick={openContacto}
                  style={{justifyContent: 'center'}}
                  //icon={<PhoneIcon mr="8px" />}
                  >
                  {/* &nbsp;<span role="img" aria-label="icon">☎️</span>&nbsp;
                  {t('Contáctanos')} */}
                  <Icon as={FcCellPhone} w={5} h={5} />{t('Contáctanos')}
                </MenuItem>
                <MenuItem m="0" p="0">
                  <LangSelector lang={lang} size='large' style={{
                    fontSize: '12px', textAlign: 'center', padding: '0', width: '100%',
                    marginTop: '8px'}}/>
                </MenuItem>
              </MenuList>
            </Menu>
          </li>
        </ul>
      </Box>
    </Container>
  )
};
Nav.propTypes = {
  openContacto: PropTypes.func.isRequired,
  closeContacto: PropTypes.func.isRequired,
  toggleContacto: PropTypes.func.isRequired,
  lang: PropTypes.string
};

const Container = styled.nav`
  margin-top: -6px;
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    align-items: baseline;

    li {
      font-size: 0.9rem;
      font-weight: 600;
      cursor: pointer;

      & + li {
        margin-left: 0rem;
      }
      //a,
      
      button {
        color: #222;
        transition: color 0.5s ease-in-out;
        text-decoration: none;
        padding: 21px 16px 20px;
        // padding: 7px 16px 7px;
        // border-radius: 32px;
        /* margin-top: -10px; */
        transition: background 0.5s ease-in-out;
        transition: text-shadow 0.1s ease-in-out;
        white-space: nowrap;
        font-weight: bold;

        &:hover,
        &:focus {
          color: #fff;
          background: var(--accent-color);
          /*outline: 3px solid black;*/
        }
      }
      .navLink {
        color: #222;
        transition: color 0.5s ease-in-out;
        text-decoration: none;
        margin-top: -10px;
        transition: background 0.5s ease-in-out;
        transition: border-radius 0.5s ease-in-out;
        white-space: nowrap;
        font-weight: bold;
        // padding: 21px 16px 18px;
        padding: 7px 16px 7px;
        border-radius: 32px;
        
        svg {
          transition: transform .5s ease-in-out;
        }

        &:hover,
        &:focus {
          // color: #fff;
          // background: var(--accent-color);
          color: var(--accent-color);
          border-radius: 32px;
          svg {
            transform: rotateY(5deg) rotateZ(-10deg) ;
          }
        }
      }
      .active {
        color: #fff;
        background: var(--accent-color);
        padding: 6px 16px 8px;
        border-radius: 32px;

        &:hover, &:focus {
          color: #eee;
        }
      }
      .active2 {
        
        
      }
      .active2 button {
        color: #fff;
        background: var(--accent-color);
      }
      button {
        margin-bottom: -5px;//-9px;
        height: 58px;
      }
      li:last-of-type {
        a,
        button {
          margin-right: 16px;
        }
      }
       .ctaNav2 {
        color: var(--accent-color);
        
       }
      .ctaNavNo {
        border: 0px solid;
        border-image-slice: 1;
        border-top-width: 4px; 
        /* border-width: 4px;  */
        border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
        
        border-image-source: linear-gradient(to left,#a028e2, var(--accent-color));
        /* margin: 2px 16px 0px 16px; */
        padding: 14px;
        height: 51px;
        transition: all 0.5s ease-in-out;
      }
      .ctaNavNo:hover {
        border: none;
        margin-bottom: -9px;
        height: 55px;
        /* padding: 21px 16px 20px; */
        margin-top: -9px;
        color: #fff;
        background: var(--accent-color);
        /* background-image: linear-gradient(to left,#a028e2, var(--accent-color)); */
      }
      .btnContact {
        color: #3c9cdc;
        text-shadow: 0.5px 0.5px white;
        svg {
          transition: transform .5s ease-in-out;
        }
      }
      svg {
        vertical-align: middle;
      }
      .btnContact:hover {
        color: #33b2f8;
        text-shadow: none;
        svg {
          transform: rotateY(15deg) rotateZ(-10deg) ;
        }
      }
    }

    li>button {
      padding: 4px 16px 4px ;
      border-radius: 32px;
      height: auto;

      &:hover,
      &:focus {
        color: var(--accent-color);
        background: transparent;
        // outline: 1px solid black;
      }
    }
  }
  .menuServicios {
    background-color: rgb(255,255,255,0.68);
  }
  .menuServicios::before {
    content: '';
    position: absolute;
    inset: 0;
    --extended-by: 100px;
    bottom: calc(-1 * var(--extended-by));
    --cutoff: calc(100% - var(--extended-by));
    backdrop-filter: blur(10px);
    z-index: -1;
    --blur: 10px;
    -webkit-backdrop-filter: blur(var(--blur));
    backdrop-filter: blur(var(--blur));
    -webkit-mask-image: linear-gradient(to bottom, black 0, black var(--cutoff), transparent var(--cutoff));
    pointer-events: none;
  }
  // .menuServicios::before {
  //   content: '';
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   backdrop-filter: blur(10px);
  //   z-index: -1;
  // }
`;

export default Nav;
