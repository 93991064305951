const routes = {
  '/': '/en',
  '/en': '/',
  // '/videos': '/en/videos',
  // '/contacto': '/en/contact',

  '/set-virtual-produccion-cine-tv': '/en/virtual-production-film',
  '/realidad-virtual': '/en/virtual-reality',
  '/realidad-aumentada': '/en/augmented-reality',
  '/arquitectura-recorrido-virtual': '/en/architecture-virtual-tours',
  '/animacion-modelado-3d': '/en/3d-animation',
  '/video-mapping': '/en/3d-mapping-projection',
  '/museos-instalaciones': '/en/interactive-museums',
  '/aplicaciones-videojuegos': '/en/apps-videogames',
  '/capacitacion-realidad-virtual': '/en/virtual-reality-training',

  '/proyectos': '/en/projects',
  '/proyectos/video-mapping-3d': '/en/projects/3d-projection-mapping',
  '/proyectos/realidad-virtual': '/en/projects/virtual-reality',
  '/proyectos/realidad-aumentada': '/en/projects/augmented-reality',
  '/proyectos/aplicaciones-y-videojuegos': '/en/projects/apps-and-videogames',
  '/proyectos/museos': '/en/projects/museums',
  '/proyectos/escenarios-virtuales': '/en/projects/virtual-production',
  '/proyectos/arquitectura': '/en/projects/virtual-architecture',
  '/proyectos/animacion-y-modelado-3d': '/en/projects/3d-animation',

}
module.exports.routes = routes
// TODO: Que logica de getLocalizedPath tenga la doble traduccion????
//revisar tambien si es ingles y existe el slug traducido 
  // const path = lang === 'es' 
  // ? (routes?.[pathname] || '/en')
  // : Object.keys(routes).find(key => routes[key] === pathname) || '/';

// getLocalizedPathFromEs (from default)
const getLocalizedPath = (key, lang) => {
  return lang === 'es' ? key : (routes?.[key] || key)
}
module.exports.getLocalizedPath = getLocalizedPath

const getEsPath = (path, currentLang) => {
  return currentLang === 'es'
  ? path
  : Object.keys(routes).find(key => routes[key] === path);
}
module.exports.getEsPath = getEsPath

const getEnPath = (path, currentLang) => {
  return currentLang === 'en'
    ? path
    : routes?.[path] || false
}
module.exports.getEnPath = getEnPath

const translations = {
  'Servicios': 'Services',
  'Proyectos': 'Projects',
  'Inicio': 'Home',
  'Producción Virtual / Cine': 'Virtual production & Virtual sets',
  'Producción Virtual': 'Virtual production',
  'Escenarios virtuales': 'Virtual production',
  'Realidad Virtual': 'Virtual Reality',
  'Realidad Aumentada': 'Augmented Reality',
  'Arquitectura Virtual': 'Virtual Architecture',
  'Arquitectura': 'Virtual Architecture',
  'Animación y Video': '3D Animation',
  'Animación 3D y Video': '3D Animation',
  'Video Mapping 3D': '3D Projection Mapping',
  'Museos': 'Museums',
  'Apps y Videojuegos': 'Apps and Videogames',
  'Aplicaciones y Videojuegos': 'Apps and Videogames',
  'Contáctanos': 'Contact us',
  '33 3825-3793': '+52 33 3825-3793',
  '3338253793': '+523338253793',
  'contacto@visualma.com': 'contact@visualma.com',
  '¡Hagamos realidad tu proyecto!': 'Let\'s make your project a reality!',
  'Video Mapping 3D, Realidad Virtual, Animación y Museos en México': 'Virtual production, Augmented Reality, Virtual Reality, 3D Mapping & 3D Animation',
  'Sets / Escenarios virtuales': 'Virtual production & Virtual sets',
  'Instalaciones para Museos': 'Museum installations',
  'Animación y Modelado 3D': '3D Animation',
  'Arquitectura y recorridos virtuales': 'Architecture and virtual tours',
  'Programación y Web': 'Web Development',
  'Capacitación con realidad virtual': 'Virtual Reality Training',

  //servicios
  'Producción virtual y Escenarios Virtuales para Producciónes de TV, Publicidad, Cine y Broadcast en vivo.': 
  'Virtual production and real time virtual sets for TV, marketing, film and live broadcast.',
  'Experiencias de Realidad Aumentada que combinan el mundo real con el mundo digital en tiempo real.': 'Augmented reality experiences that combine the real world with the digital world in real time.', 
  'Poderosas ilusiones sobre fachadas y escenarios que impactan a una gran cantidad de espectadores.': 'Powerful illusions over buildings and stages that impact a large number of spectators.',
  'Mundos interactivos envolventes que permiten que el usuario viva el universo de nuestros clientes.': 'Interactive and immersive world\'s that allow users to live the universe of our clients.',
  'Contenidos animados y modelado 3D de alto nivel para cinematografía, televisión, marketing y publicidad.': 'Hi-end 3D Animation and modeling for film, television, marketing and corporate events.', 
  'Recorridos virtuales arquitectonicos fotorrealistas, interactivos y personalizables que comunican proyectos y cierran ventas.': 'Photorealistic,  interactive & customizable virtual tours for architecture that help close real estate sales.', 
  'Instalaciónes audiovisuales que logran visibilidad en medios y atraen nuevos públicos a museos y exposiciones.': 'Audio visual installations that achieve visibility in the media and attract new public to museums and exhibitions', 
  'Juegos y aplicaciónes para dispositivos móviles, web, pc\'s y consolas.': 'Video games and applications for mobile devices, web, pc\'s & consoles.',
  'Utiliza la Realidad Virtual como una herramienta invaluable para el entrenamiento de tus empleados.': 'Use virtual reality as an invaluable tool for training employees.',
}
module.exports.translations = translations
const useTranslation = (key, lang, texts = translations) => {
  return lang === 'en'
    ? texts?.[key] || key
    : key
}
module.exports.useTranslation = useTranslation

// const siteEs = {
const site = {
  'es': {
    siteTitle: 'Visualma, México',
    siteTitleShort: 'Visualma Mexico',
    defaultTitle:
      'Visualma - Escenarios virtuales, Realidad Virtual, Realidad Aumentada, Animación 3D, Video Mapping 3D y Museos en México.',
    siteDescription:
      'Especialistas en escenarios virtuales, realidad virtual, realidad aumentada, video mapping 3D, instalaciones interactivas, recorridos virtuales, museos y animación 3D en México.',
  },
  'en': {
    siteTitle: 'Visualma',
    siteTitleShort: 'Visualma',
    defaultTitle:
      'Visualma - Virtual Production, Virtual Reality, Augmented Reality, 3D Animation and interactive experiences.',
    siteDescription:
      'We specialize in virtual production, virtual reality, augmented reality, 3D projection mapping, interactive museums, virtual photorealistic tours & 3D animation.',
  }
}
module.exports.site = site

const tags = {
  'es': {
    'Aplicaciones y Videojuegos': 
      'Algunos de los proyectos de Aplicaciones y Videojuegos realizados por Visualma en nuestros diez años de experiencia como creadores de contenido digital en México.',
    'Animación y Modelado 3D': 
      'Nos especializamos en contenidos animados y modelado 3D de alto nivel para cinematografía, televisión, marketing, comerciales y publicidad en México',
    'Video Mapping 3D': 
      'Con técnicas de Video mapping 3D creamos poderosas ilusiones sobre fachadas y escenarios que impactan a una gran cantidad de espectadores en México y el mundo.',
    'Realidad Virtual': 
      'Mundos interactivos envolventes en realidad virtual que permiten que el usuario viva el universo de nuestros clientes en todo tipo de dispositivos.',
    'Realidad Aumentada': 
      'Experiencias de Realidad Aumentada que combinan el mundo real con el mundo digital en tiempo real y pueden ser utilizadas en teléfonos, tabletas, computadoras y lentes especializados',
    'Museos': 
      'Instalaciónes audiovisuales que logran visibilidad en medios y atraen nuevos públicos a museos y exposiciones.',
    'Arquitectura': 
      'Recorridos virtuales arquitectonicos fotorrealistas, interactivos y personalizables que comunican proyectos y cierran ventas.',
    'Escenarios virtuales': 
      'Somos expertos en Producción virtual y Escenarios Virtuales para producciónes de TV, Publicidad, Cine, streaming y Broadcast en vivo.',
    'Capacitación con Realidad Virtual':
      'Desarrollamos aplicaciones de capacitación con realidad virtual y entrenamientos inmersivos en México.',
  },
  'en': {
    'Aplicaciones y Videojuegos':
      'Some of the applications and videogames developed by visualma in our more than 10 years of experience creating digital content and developing software.',
    'Animación y Modelado 3D':
      'We specialize in high-end animated content and 3D modeling for filmmaking, television, marketing and interactive experiences. Capture your audience with unique and memorable content that represents the essence of your brand.',
    'Video Mapping 3D':
      'Using video projection mapping techniques we create powerful and memorable illusions that impact a large audience on structures and buildings.',
    'Realidad Virtual':
      'Interactive and immersive worlds in virtual reality that allow the user to live the universe of our clients in every kind of device.',
    'Realidad Aumentada':
      'Augmented reality experiences that combine the real world with the digital world in real time and can be used in phones, tablets, computers and special glasses.',
    'Museos':
      'Audio visual installations that generate a presence in the media and attract new audiences to museums and expositions.',
    'Arquitectura':
      'Interactive and customizable Virtual Photorealistic Architectonic Tours that boost sales and reduce costs. Solutions designed to make pre-sale and sale process easier and help with the development and planning of architectural projects.',
    'Escenarios virtuales':
      'We specialize in virtual production and virtual sets for TV productions, film, marketing, streaming, corporate online events and live broadcast with real time vfx.',
    'Capacitación con Realidad Virtual':
      'We develop training courses with virtual reality and immersive training.',
  },
}
module.exports.tags = tags
// TODO: lenguajes con keys para no tener que cambiar texto en español en dos lugares
// TODO: metaTagCreator con hrefLang
